<template>
  <div class="bookmaster-wraper">
    <app-modal-confirm
      ref="apikey_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteApiKey()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="user_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteCustomer()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <template v-if="idEditing">
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                  <label class="col-xl-1 col-lg-2 col-sm-2 m-t-8">{{ entry.id }}</label>
                </div>
              </template>
              <validation-provider
                name="status"
                rules="required"
                v-slot="{ errors, invalid, validated }"
              >
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("kyy_customers.status") }} <span class="app-require">＊</span>
                  </label>
                  <div class="col-xl-5 col-lg-6 col-sm-6 align-self-center">
                    <app-radio :label="$t('kyy_customers.status_options.active')" val="1" v-model="entry.status"/>
                    <app-radio :label="$t('kyy_customers.status_options.inactive')" isMarginLeft="true" val="2" v-model="entry.status"/>
                    <label v-if="invalid && validated" class="error">
                      {{ errors[0] }}
                    </label>
                  </div>
                </div>
              </validation-provider>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("kyy_customers.user_name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_customers_user_name"
                    type="string128"
                    rules="required"
                    v-model="entry.user_name"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("kyy_customers.email") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_customers_email"
                    type="string128"
                    rules="required|email"
                    v-model="entry.email"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("kyy_customers.phone_number") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_customers_phone_number"
                    type="string128"
                    rules="required|phone_number"
                    v-model="entry.phone_number"
                    placeholder="090-1234-5678"
                  />
                </div>
              </div>

              <div class="form-group row" v-if="!idEditing">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("kyy_customers.password") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_customers_password"
                    type="string128"
                    rules="required"
                    v-model="entry.password"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("kyy_customers.customer_name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_customers_customer_name"
                    type="string128"
                    rules="required"
                    v-model="entry.customer_name"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("kyy_customers.student_name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_customers_student_name"
                    type="string128"
                    rules="required"
                    v-model="entry.student_name"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("kyy_customers.student_class") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-select
                    name="kyy_customers_student_class"
                    type="string128"
                    rules="required"
                    :options-data="classList"
                    v-model="entry.student_class"
                  />
                </div>
              </div>

              <div class="card-footer row justify-content-end">
                <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                  {{ $t('common_vn.cancel') }}
                </button>
                <button v-if="idEditing" type="button" :disabled="!valid && validated" class="btn btn-danger m-r-10" @click="handleDelete">
                  {{ $t('common_vn.delete') }}
                </button>
                <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary m-r-10" @click="createOrUpdate" type="button">
                  {{  $t('common_vn.save') }}
                </button>
                <button v-else :disabled="!valid && validated" class="btn btn-primary m-r-10" type="button" @click="createOrUpdate" >
                  {{  $t('common_vn.create') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { createHmac } from 'crypto';
import AppTextarea from "../../../components/forms/AppTextarea";
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
import {ROUTES} from "@constants";
import path from 'path';
import { type } from 'os';
import { CLASSES } from '../../../constants';
export default {
  name: "BookMasterCreateOrEdit",

  components: {
    AppTextarea,
    AppDateRangePicker,
  },

  data() {
    const id = this.$route.params.id
    return {
      entry: {
        user_name: '',
        email: '',
        password: '',
        customer_name: '',
        phone_number: '',
        type: 1,
        status: 1,
        student_name: '',
        student_class: '',
      },
      classList: [],
      apiKeyId: null,
      meta: {
        apartments:[],
        opening_time:[],
        closing_time:[],
      },
      minDate : moment().format('YYYY-MM-DD'),
      idEditing: id,
      entryNameUpdate: "",
      startValidate : !!id,
    };
  },
  watch: {
  },

  mounted: async function () {
    this.classList = CLASSES.map(item => {
      return {
        id: item,
        name: item
      }
    })
    if (this.idEditing) {
      this.startValidate = false;
      let response = await this.$request.get(this.ENDPOINT.KYY_CUSTOMERS_EDIT(this.idEditing));
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
        await this.redirectDefaultValue()
      } else {
        this.entry = _.cloneDeep(response.data.data);
        this.startValidate = true;
      }
    }
  },

  methods: {
    confirmCancel()
    {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.KYY_CUSTOMERS_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
    async createOrUpdate() { 
      this.startValidate = true;
      if (!await this.$refs.form_create_entry.validate()) {
        return;
      }
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.KYY_CUSTOMERS_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.KYY_CUSTOMERS_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        await this.redirectDefaultValue();
      }
    },

    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.KYY_CUSTOMERS_LIST, query: {}
      });
    },
    handleDelete() {
      this.$refs.user_modal_delete.show();
    },
    async deleteCustomer() {
      const res = await this.$request.delete(this.ENDPOINT.KYY_CUSTOMERS_DELETE(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        await this.redirectDefaultValue();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.user_modal_delete.hide();
      }
    },
  },
};
</script>
<style>
@media (min-width: 1200px) {
  .col-unit {
    flex: 0 0 14%;
  }
}

</style>
